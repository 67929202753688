import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { NumberInput } from './common';
import { PolicyFormProps } from './types';
import { AllOrSingleOperatorSelect } from './operator-selectors';
import VehicleClassInput from './vehicle-class-selector';
import DayOfWeekInput from './day-of-week-selector';

function MaxIdleTimeForm({
    policy,
    setPolicy,
    formErrors,
    setFormErrors
}: PolicyFormProps) {
    useEffect(() => {
        const hasMaximumError = policy.maximum == null;
        // set a maximum error if it doesn't already exist
        if (formErrors.maximum !== hasMaximumError) {
            setFormErrors({
                ...formErrors,
                maximum: hasMaximumError
            });
        }
    }, [policy.maximum, formErrors, setFormErrors]);

    return (
        <>
            <DayOfWeekInput
                days={policy.days}
                onChange={days =>
                    setPolicy({
                        ...policy,
                        days
                    })
                }
            />

            <VehicleClassInput policy={policy} setPolicy={setPolicy} />

            <NumberInput
                width={96}
                label={
                    <FormattedMessage
                        key="max-idle-hours-label"
                        defaultMessage="Max Idle Hours"
                    />
                }
                id="idle-time-input"
                value={policy.maximum ?? ''}
                min="0"
                max="168"
                onChange={newValue => {
                    const amount = parseInt(newValue);
                    setPolicy({
                        ...policy,
                        maximum: Number.isNaN(amount) ? undefined : amount
                    });
                }}
            />

            <AllOrSingleOperatorSelect
                policy={policy}
                setPolicy={setPolicy}
                formErrors={formErrors}
                setFormErrors={setFormErrors}
            />
        </>
    );
}

export default MaxIdleTimeForm;

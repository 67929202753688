import React from 'react';
import { useRef, useEffect } from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/area-origin-destination-reports/popup.tsx?resplendence=true";

import { Map, Popup } from 'mapbox-gl';
import { defineMessages, useIntl } from 'react-intl';
import Icon from 'common/icon';
import { AreaOfInterest, Metric } from './reducer';
import { AreaCount } from './effects';

const m = defineMessages({
    'tooltip-starts-selected-same':
        '<b>{count, number}</b> trips started {startsIcon} and ended {endsIcon} in the selected area.',
    'tooltip-starts-selected':
        '<b>{count, number}</b> trips started in the selected area and ended {endsIcon} here.',
    'tooltip-starts-all':
        '<b>{count, number}</b> starts {startsIcon}. Click to see where these trips ended {endsIcon}.',
    'tooltip-ends-selected-same':
        '<b>{count, number}</b> trips started {startsIcon} and ended {endsIcon} in the selected area.',
    'tooltip-ends-selected':
        '<b>{count, number}</b> trips started {startsIcon} here and ended in the selected area.',
    'tooltip-ends-all':
        '<b>{count, number}</b> ends {endsIcon}. Click to see where these trips started {startsIcon}.',
    'tooltip-percentage-starts-selected-same':
        '<b>{percentage, number}%</b> of all trips that started in the selected area.',
    'tooltip-percentage-starts-selected':
        '<b>{percentage, number}%</b> of all trips that started in the selected area.',
    'tooltip-percentage-starts-all':
        '<b>{percentage, number}%</b> of all trips that started in one of the measured areas.',
    'tooltip-percentage-ends-selected':
        '<b>{percentage, number}%</b> of all trips that ended in the selected area.',
    'tooltip-percentage-ends-selected-same':
        '<b>{percentage, number}%</b> of all trips that ended in the selected area.',
    'tooltip-percentage-ends-all':
        '<b>{percentage, number}%</b> of all trips that started in one of the measured areas.'
});

/*
@import 'style.scss';
*/;
const POPUP = "rx-page-explore-page-heatmaps-area-origin-destination-reports-popup-1"/*
    @include text-label;
    padding: 8rem;
    pointer-events: none;
    color: $gray-70;
    svg {
        fill: $blue-50;
        font-size: 28rem;
        width: 1em;
        height: 1em;
        margin: -9rem -3rem;
    }
*/;
const POPUP_NUMBER = "rx-page-explore-page-heatmaps-area-origin-destination-reports-popup-2"/*
    @include text-label-bold;
    color: $gray-90;
*/;

type AreaOriginDestinationReportPopupProps = {
    map: Map;
    hoveredArea: AreaOfInterest | null;
    hoveredAreaData: AreaCount | null;
    selectedAreaId: string | null;
    metric: Metric;
};

export const findCenter = (area: AreaOfInterest) => {
    // Finds the rough center of the multi-polygon area.
    // This doesn't have to be precise, we just need a point to place the popup.
    const polygons = area.geometry.coordinates;
    const arrayDepth = (arr: any): number => {
        if (Array.isArray(arr)) {
            return 1 + Math.max(...arr.map(arrayDepth));
        }
        return 0;
    };
    const coords = polygons.flat(arrayDepth(polygons) - 2);
    const lats: Array<number> = [];
    const lngs: Array<number> = [];
    coords.forEach(coord => {
        lats.push(coord[1]);
        lngs.push(coord[0]);
    });
    const centerLat =
        lats.reduce(function(a, b) {
            return a + b;
        }, 0) / lats.length;
    const centerLng =
        lngs.reduce(function(a, b) {
            return a + b;
        }, 0) / lngs.length;
    return [centerLng, centerLat];
};

const AreaOriginDestinationReportPopup = ({
    map,
    hoveredArea,
    hoveredAreaData,
    selectedAreaId,
    metric
}: AreaOriginDestinationReportPopupProps) => {
    const intl = useIntl();
    const popupRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (popupRef.current && hoveredArea) {
            const lngLat = findCenter(hoveredArea);
            const copy = popupRef.current.cloneNode(true);
            const popup = new Popup({ maxWidth: '480rem' })
                .setDOMContent(copy)
                .setLngLat([lngLat[0], lngLat[1]])
                .addTo(map);
            return () => {
                popup.remove();
            };
        }
    }, [map, hoveredArea]);

    const count = hoveredAreaData !== null ? hoveredAreaData.count : 0;
    const percentage =
        hoveredAreaData !== null ? hoveredAreaData.percentage : 0;
    const popupArea = selectedAreaId
        ? selectedAreaId === hoveredArea?.id
            ? 'selected-same'
            : 'selected'
        : 'all';

    return (
        <div style={{ display: 'none' }}>
            <div
                className={POPUP}
                ref={popupRef}
                key={`tooltip-${metric}-${popupArea}`}
            >
                {intl.formatMessage(m[`tooltip-${metric}-${popupArea}`], {
                    count: count ?? 0,
                    b: msg => [
                        <span
                            key={`number-${metric}-${popupArea}`}
                            className={POPUP_NUMBER}
                        >
                            {msg}
                        </span>
                    ],
                    startsIcon: (
                        <Icon
                            key={`start-icon-percentage-${metric}-${popupArea}`}
                            icon="Trip-Start"
                        />
                    ),
                    endsIcon: (
                        <Icon
                            key={`end-icon-percentage-${metric}-${popupArea}`}
                            icon="Trip-End"
                        />
                    )
                })}
                <br />
                <div>
                    {intl.formatMessage(
                        m[`tooltip-percentage-${metric}-${popupArea}`],
                        {
                            percentage: percentage.toFixed(1) ?? 0.0,
                            b: msg => [
                                <span
                                    key={`tooltip-${metric}-${popupArea}`}
                                    className={POPUP_NUMBER}
                                >
                                    {msg}
                                </span>
                            ]
                        }
                    )}
                </div>
            </div>
        </div>
    );
};

export default AreaOriginDestinationReportPopup;

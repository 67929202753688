import { gql } from '@apollo/client';
import { useMapView } from 'common/use-map-view';
import { useReloadingData } from 'utils/use-data';
import { useMemo } from 'react';
import {
    AreaOriginDestinationReportArgs,
    AreaOriginDestinationReportData,
    AreaOriginDestinationReportsInfoArgs,
    AreaOriginDestinationReportsInfoData
} from 'graphql.g';
import {
    AreaOriginDestinationReportMetadata,
    Metric
} from 'page-explore/page-heatmaps/area-origin-destination-reports/reducer';

export interface OriginDestinationReportData {
    data: Array<{
        area: string;
        count: number;
    }>;
    total: number;
}

interface AreaOriginDestinationReportParams {
    areaOriginDestinationReportId: string | null;
    metric: Metric;
    selectedAreaId: string | null;
}

// GQL query to get an area o/d report by id, including it's data.
const AREA_ORIGIN_DESTINATION_REPORT_QUERY = gql`
    query AreaOriginDestinationReport(
        $slug: String!
        $areaOriginDestinationReportId: ID!
        $metric: String!
        $selectedAreaId: ID
    ) {
        mapView(slug: $slug) {
            id
            areaOriginDestinationReport(
                areaOriginDestinationReportId: $areaOriginDestinationReportId
                metric: $metric
                selectedAreaId: $selectedAreaId
            ) {
                data
                total
            }
        }
    }
`;

// GQL query to get all area o/d reports for the mapview. This includes all metadata, but no data.
const AREA_ORIGIN_DESTINATION_REPORTS_INFO_QUERY = gql`
    query AreaOriginDestinationReportsInfo($slug: String!) {
        mapView(slug: $slug) {
            id
            areaOriginDestinationReports {
                id
                name
                startDate
                endDate
                startTime
                endTime
                days
                status
                areas {
                    id
                    name
                    slug
                    geometryJson
                }
                operators {
                    id
                    name
                }
                vehicleClasses
            }
        }
    }
`;

/**
 * Fetches the area origin destination report data for the given area origin destination report id.
 * All filtering is done on the server side, and filters are only
 * displayed staticly.
 */
export function useAreaOriginDestinationReport(
    args: AreaOriginDestinationReportParams
): [OriginDestinationReportData | null, boolean] {
    const { slug } = useMapView();
    const [areaOriginDestinationReport, isLoading] = useReloadingData<
        AreaOriginDestinationReportData,
        AreaOriginDestinationReportArgs
    >(
        AREA_ORIGIN_DESTINATION_REPORT_QUERY,
        args.areaOriginDestinationReportId === null
            ? 'skip'
            : {
                  slug,
                  areaOriginDestinationReportId:
                      args.areaOriginDestinationReportId,
                  metric: args.metric,
                  selectedAreaId: args.selectedAreaId
              }
    );

    const parsedAreaOriginDestinationReport = useMemo(() => {
        if (
            areaOriginDestinationReport === null ||
            areaOriginDestinationReport.mapView.areaOriginDestinationReport ===
                null
        ) {
            return null;
        }

        const {
            data,
            total
        } = areaOriginDestinationReport.mapView.areaOriginDestinationReport;

        return {
            data,
            total
        };
    }, [areaOriginDestinationReport]);

    return [parsedAreaOriginDestinationReport, isLoading];
}

/**
 * Fetches all area origin destination reports for the current mapview.
 */
export function useAreaOriginDestinationReportsInfo(): [
    AreaOriginDestinationReportMetadata[],
    boolean
] {
    const { slug } = useMapView();

    const [areaOriginDestinationReports, isLoading] = useReloadingData<
        AreaOriginDestinationReportsInfoData,
        AreaOriginDestinationReportsInfoArgs
    >(AREA_ORIGIN_DESTINATION_REPORTS_INFO_QUERY, {
        slug
    });

    const parsedAreaOriginDestinationReports = useMemo(() => {
        if (
            areaOriginDestinationReports === null ||
            areaOriginDestinationReports.mapView
                .areaOriginDestinationReports === null
        ) {
            return [];
        }

        const areaOriginDestinationReportsData = areaOriginDestinationReports
            .mapView
            .areaOriginDestinationReports as AreaOriginDestinationReportMetadata[];

        return areaOriginDestinationReportsData;
    }, [areaOriginDestinationReports]);

    return [
        parsedAreaOriginDestinationReports !== null
            ? parsedAreaOriginDestinationReports
            : [],
        isLoading
    ];
}

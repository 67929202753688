import { Day, VehicleClass } from 'graphql.g';
import { OperatorName } from 'constants/operators';
import { MultiPolygon } from 'geojson';
import { OriginDestinationReportData } from 'page-explore/layers/area-origin-destination-counts/api';
import { AreaCount } from './effects';

export type AreaOfInterest = {
    id: string;
    name: string;
    geometry: MultiPolygon;
    color: string;
};

export type SelectedArea = {
    id: string;
    name: string;
    geometry: MultiPolygon;
};

export type Metric = 'starts' | 'ends';

export type AreaOriginDestinationReportActions =
    | {
          type: 'SET_AREA_ORIGIN_DESTINATION_REPORTS_INFO';
          payload: {
              areaOriginDestinationReportsInfo: AreaOriginDestinationReportMetadata[];
          };
      }
    | {
          type: 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_DATA';
          payload: {
              areaOriginDestinationReport: OriginDestinationReportData | null;
          };
      }
    | {
          type: 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_INFO';
          payload: {
              selectedAreaOriginDestinationReportInfo: AreaOriginDestinationReportMetadata;
          };
      }
    | {
          type: 'SET_SELECTED_AREA';
          payload: {
              selectedArea: AreaOfInterest | null;
          };
      }
    | {
          type: 'SET_HOVERED_AREA';
          payload: {
              hoveredArea: AreaOfInterest | null;
          };
      }
    | {
          type: 'SET_HOVERED_AREA_DATA';
          payload: {
              hoveredAreaData: AreaCount | null;
          };
      }
    | {
          type: 'SET_METRIC';
          payload: {
              metric: Metric;
          };
      }
    | {
          type: 'SET_LOADING';
          payload: { loading: boolean };
      };

export interface AreaOriginDestinationReportMetadata {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    days: Day[];
    status: string;
    areas: {
        id: string;
        name: string;
        slug: string;
        geometryJson: string;
    }[];
    operators: {
        id: string;
        name: OperatorName;
    }[];
    vehicleClasses: VehicleClass[];
}

export interface AreaOriginDestinationReportState {
    areaOriginDestinationReportsInfo: AreaOriginDestinationReportMetadata[];
    selectedAreaOriginDestinationReportData: OriginDestinationReportData;
    selectedAreaOriginDestinationReportInfo: AreaOriginDestinationReportMetadata | null;
    loading: boolean;
    selectedArea: AreaOfInterest | null;
    selectedAreaData: AreaCount | null;
    hoveredArea: AreaOfInterest | null;
    hoveredAreaData: AreaCount | null;
    metric: 'starts' | 'ends';
}

export const areaOriginDestinationReportInitialState: AreaOriginDestinationReportState = {
    areaOriginDestinationReportsInfo: [],
    selectedAreaOriginDestinationReportData: {
        data: [],
        total: 0
    },
    selectedAreaOriginDestinationReportInfo: null,
    loading: false,
    selectedArea: null,
    selectedAreaData: null,
    hoveredArea: null,
    hoveredAreaData: null,
    metric: 'ends'
};

export function areaOriginDestinationReportReducer(
    state: Readonly<AreaOriginDestinationReportState>,
    action: AreaOriginDestinationReportActions
): Readonly<AreaOriginDestinationReportState> {
    switch (action.type) {
        case 'SET_AREA_ORIGIN_DESTINATION_REPORTS_INFO':
            return {
                ...state,
                areaOriginDestinationReportsInfo:
                    action.payload.areaOriginDestinationReportsInfo
            };
        case 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_DATA':
            return {
                ...state,
                selectedAreaOriginDestinationReportData: action.payload
                    .areaOriginDestinationReport ?? {
                    data: [],
                    total: 0
                }
            };
        case 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_INFO':
            return {
                ...state,
                selectedAreaOriginDestinationReportInfo:
                    action.payload.selectedAreaOriginDestinationReportInfo
            };
        case 'SET_SELECTED_AREA':
            return {
                ...state,
                selectedArea: action.payload.selectedArea
            };
        case 'SET_HOVERED_AREA':
            return {
                ...state,
                hoveredArea: action.payload.hoveredArea
            };
        case 'SET_HOVERED_AREA_DATA':
            return {
                ...state,
                hoveredAreaData: action.payload.hoveredAreaData
            };
        case 'SET_METRIC': {
            return {
                ...state,
                metric: action.payload.metric
            };
        }
        case 'SET_LOADING': {
            return {
                ...state,
                loading: action.payload.loading
            };
        }
        default:
            return state;
    }
}

import React, { Dispatch } from 'react';
import "/opt/build/repo/src/page-explore/page-heatmaps/area-origin-destination-reports/metadataPanel.tsx?resplendence=true";

import {
    defineMessages,
    FormattedMessage,
    FormattedTime,
    useIntl
} from 'react-intl';

import {
    AreaOriginDestinationReportActions,
    AreaOriginDestinationReportMetadata,
    Metric
} from './reducer';
import TotalCount from 'page-explore/common/total-count';
import KeyValue from 'common/key-value';
import { dayStrings, getDayOfWeekType } from 'page-policy/policy';
import { separatedBy } from 'utils/helpers';
import { OperatorTag } from 'common/tag';
import { getDateTime } from 'page-policy/util';
import Icon from 'common/icon';
import { ICON_FOR_VEHICLE_CLASS } from 'common/vehicle-classes';
import { OriginDestinationReportData } from 'page-explore/layers/area-origin-destination-counts/api';
import { TabToggle } from 'common/layout';

/*
@import 'style.scss';
*/;

const AREA_ORIGIN_DESTINATION_REPORT_METADATA = "rx-page-explore-page-heatmaps-area-origin-destination-reports-metadataPanel-1"/*
    display: flex;
    flex-direction: column;
    gap: 16rem;
*/;

const messages = defineMessages({
    starts: 'Trip Starts',
    ends: 'Trip Ends'
});

interface AreaOriginDestinationMetadataPanelProps {
    areaOriginDestinationReportInfo: AreaOriginDestinationReportMetadata;
    areaOriginDestinationReportData: OriginDestinationReportData;
    metric: Metric;
    dispatch: Dispatch<AreaOriginDestinationReportActions>;
}

const AreaOriginDestinationReportMetadataPanel = ({
    areaOriginDestinationReportInfo,
    areaOriginDestinationReportData,
    metric,
    dispatch
}: AreaOriginDestinationMetadataPanelProps) => {
    const intl = useIntl();

    const totalTripCount = areaOriginDestinationReportData.total;

    const dayOfWeekType = getDayOfWeekType(
        areaOriginDestinationReportInfo.days
    );

    return (
        <div className={AREA_ORIGIN_DESTINATION_REPORT_METADATA}>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="rideshed-dates-label"
                        defaultMessage="Dates"
                    />
                }
            >
                {areaOriginDestinationReportInfo.startDate} -{' '}
                {areaOriginDestinationReportInfo.endDate}
            </KeyValue>
            {areaOriginDestinationReportInfo.startTime && (
                <KeyValue
                    keyName={
                        <FormattedMessage
                            key="policy-rule-time-label"
                            defaultMessage="Time"
                        />
                    }
                >
                    <FormattedTime
                        value={getDateTime(
                            areaOriginDestinationReportInfo.startTime
                        )}
                    />
                    {/* if we have an end time, and it differs from our start time, show the range */}
                    {areaOriginDestinationReportInfo.endTime &&
                        areaOriginDestinationReportInfo.endTime !==
                            areaOriginDestinationReportInfo.startTime && (
                            <>
                                {' '}
                                -{' '}
                                <FormattedTime
                                    value={getDateTime(
                                        areaOriginDestinationReportInfo.endTime
                                    )}
                                />
                            </>
                        )}
                </KeyValue>
            )}

            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-rule-day-of-week-label"
                        defaultMessage="Day of Week"
                    />
                }
            >
                {dayOfWeekType === 'all' ? (
                    <FormattedMessage
                        key="day-of-week-all"
                        defaultMessage="All"
                    />
                ) : dayOfWeekType === 'weekend' ? (
                    <FormattedMessage
                        key="day-of-week-weekend"
                        defaultMessage="Weekends"
                    />
                ) : dayOfWeekType === 'weekdays' ? (
                    <FormattedMessage
                        key="day-of-week-weekdays"
                        defaultMessage="Weekdays"
                    />
                ) : (
                    areaOriginDestinationReportInfo.days
                        .map(day => intl.formatMessage(dayStrings[day]))
                        .join(', ')
                )}
            </KeyValue>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-providers-label"
                        defaultMessage="Providers"
                    />
                }
            >
                {separatedBy(
                    areaOriginDestinationReportInfo.operators.map(operator => (
                        <OperatorTag key={operator.id} name={operator.name} />
                    )),
                    ' '
                )}
            </KeyValue>
            <KeyValue
                keyName={
                    <FormattedMessage
                        key="policy-rule-vehicle-classes-label"
                        defaultMessage="Vehicle Class"
                    />
                }
            >
                {areaOriginDestinationReportInfo.vehicleClasses.length === 0 ? (
                    <FormattedMessage
                        key="vehicle-classes-all"
                        defaultMessage="All"
                    />
                ) : (
                    separatedBy(
                        areaOriginDestinationReportInfo.vehicleClasses.map(
                            vc => (
                                <Icon
                                    key={vc}
                                    icon={ICON_FOR_VEHICLE_CLASS[vc]}
                                />
                            )
                        ),
                        ' '
                    )
                )}
            </KeyValue>
            <TabToggle
                label={
                    <FormattedMessage
                        key="select-metric-label"
                        defaultMessage="Metric"
                    />
                }
                tabLabels={[
                    intl.formatMessage(messages['starts']),
                    intl.formatMessage(messages['ends'])
                ]}
                checked={metric === 'ends'}
                onChange={value => {
                    dispatch({
                        type: 'SET_METRIC',
                        payload: {
                            metric: value ? 'ends' : 'starts'
                        }
                    });
                }}
            />
            <TotalCount count={totalTripCount}>
                <FormattedMessage
                    key="total-trips"
                    defaultMessage="Total Trips"
                />
            </TotalCount>
        </div>
    );
};

export default AreaOriginDestinationReportMetadataPanel;

import React, { useEffect, useReducer } from 'react';
import { MapOptionsAction, MapOptions } from 'page-explore';
import "/opt/build/repo/src/page-explore/page-heatmaps/area-origin-destination-reports/index.tsx?resplendence=true";

import { Map } from 'mapbox-gl';
import { FormattedMessage } from 'react-intl';
import { OVERLAY } from '../../common/overlay';
import ModePicker from '../../common/mode-picker';

import { Select } from 'common/select';
import { useQueryState } from 'router';
import { areaOriginDestinationReportReducer } from './reducer';
import { areaOriginDestinationReportInitialState } from './reducer';
import AreaOriginDestinationReportSidebar from './sidebar';
import AreaOriginDestinationReportPopup from './popup';
import { useAreaOriginDestinationReportEffects } from './effects';
import AreaOriginDestinationReportMetadataPanel from './metadataPanel';

/*
@import 'style.scss';
*/;

const SELECT_WRAPPER = "rx-page-explore-page-heatmaps-area-origin-destination-reports-index-1"/*
    display: grid;
*/;

interface MapAreaOriginDestinationReportProps {
    map: Map | null;
    mapOptions: MapOptions;
    dispatchMapOptions: React.Dispatch<MapOptionsAction>;
}

const MapAreaOriginDestinationReports = ({
    map,
    mapOptions,
    dispatchMapOptions
}: MapAreaOriginDestinationReportProps) => {
    const [
        areaOriginDestinationReportState,
        areaOriginDestinationReportDispatch
    ] = useReducer(
        areaOriginDestinationReportReducer,
        areaOriginDestinationReportInitialState
    );

    useAreaOriginDestinationReportEffects(
        map,
        dispatchMapOptions,
        areaOriginDestinationReportState,
        areaOriginDestinationReportDispatch
    );

    const [
        areaOriginDestinationReportId,
        setAreaOriginDestinationReportId
    ] = useQueryState<string | null>('areaOriginDestinationReportId' as any, {
        active: true,
        addToHistory: true
    });
    // if we're loading this page for the first time from a link with an areaOriginDestinationReportId, we need to set the selectedAreaOriginDestinationReportInfo
    // using the areaOriginDestinationReportId from the query params. If not, we should select the first completed area o/d report in the list.
    const selectedAreaOriginDestinationReportInfo =
        areaOriginDestinationReportState.areaOriginDestinationReportsInfo.find(
            areaOriginDestinationReport =>
                areaOriginDestinationReport.id === areaOriginDestinationReportId
        ) ||
        areaOriginDestinationReportState.areaOriginDestinationReportsInfo.find(
            areaOriginDestinationReport =>
                areaOriginDestinationReport.status === 'completed'
        );

    if (
        selectedAreaOriginDestinationReportInfo &&
        !areaOriginDestinationReportState.selectedAreaOriginDestinationReportInfo
    ) {
        setAreaOriginDestinationReportId(
            selectedAreaOriginDestinationReportInfo.id
        );
        areaOriginDestinationReportDispatch({
            type: 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_INFO',
            payload: {
                selectedAreaOriginDestinationReportInfo: selectedAreaOriginDestinationReportInfo
            }
        });
    }

    useEffect(() => {
        if (areaOriginDestinationReportState.hoveredArea !== null) {
            const hoveredAreaData = areaOriginDestinationReportState.selectedAreaOriginDestinationReportData?.data.find(
                areaData =>
                    areaData.area ===
                    areaOriginDestinationReportState.hoveredArea?.id
            );
            const count = hoveredAreaData?.count || 0;
            const total =
                areaOriginDestinationReportState
                    .selectedAreaOriginDestinationReportData.total;
            const percentage = total === 0 ? 0 : (count / total) * 100;
            areaOriginDestinationReportDispatch({
                type: 'SET_HOVERED_AREA_DATA',
                payload: {
                    hoveredAreaData: {
                        count: count,
                        percentage: percentage
                    }
                }
            });
        }
    }, [
        areaOriginDestinationReportState.hoveredArea,
        areaOriginDestinationReportState.selectedAreaOriginDestinationReportData
            .total,
        areaOriginDestinationReportState.selectedAreaOriginDestinationReportData
            .data
    ]);

    return (
        <>
            <div className={OVERLAY}>
                {/* Selects between heatmaps types */}
                <ModePicker
                    options={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                >
                    {/* Selects between area o/d reports */}
                    <div className={SELECT_WRAPPER}>
                        <label>
                            <FormattedMessage
                                key="select-area-origin-destination-reports-label"
                                defaultMessage="Area Origin Destination Reports"
                            />
                        </label>
                        <Select
                            value={{
                                value: areaOriginDestinationReportId,
                                label: areaOriginDestinationReportState.areaOriginDestinationReportsInfo.find(
                                    areaOriginDestinationReport =>
                                        areaOriginDestinationReport.id ===
                                        areaOriginDestinationReportId
                                )?.name
                            }}
                            onChange={option => {
                                setAreaOriginDestinationReportId(
                                    option?.value || null
                                );
                                const selectedAreaOriginDestinationReportInfo = areaOriginDestinationReportState.areaOriginDestinationReportsInfo.find(
                                    areaOriginDestinationReport =>
                                        areaOriginDestinationReport.id ===
                                        option?.value
                                );
                                selectedAreaOriginDestinationReportInfo &&
                                    areaOriginDestinationReportDispatch({
                                        type:
                                            'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_INFO',
                                        payload: {
                                            selectedAreaOriginDestinationReportInfo: selectedAreaOriginDestinationReportInfo
                                        }
                                    });
                            }}
                            options={
                                areaOriginDestinationReportState.areaOriginDestinationReportsInfo
                                    .filter(
                                        areaOriginDestinationReport =>
                                            areaOriginDestinationReport.status ===
                                            'completed'
                                    )
                                    .map(areaOriginDestinationReport => ({
                                        value: areaOriginDestinationReport.id,
                                        label: areaOriginDestinationReport.name
                                    })) || []
                            }
                        />
                    </div>
                    {areaOriginDestinationReportState.selectedAreaOriginDestinationReportInfo &&
                        areaOriginDestinationReportState.selectedAreaOriginDestinationReportData && (
                            <AreaOriginDestinationReportMetadataPanel
                                areaOriginDestinationReportInfo={
                                    areaOriginDestinationReportState.selectedAreaOriginDestinationReportInfo
                                }
                                areaOriginDestinationReportData={
                                    areaOriginDestinationReportState.selectedAreaOriginDestinationReportData
                                }
                                metric={areaOriginDestinationReportState.metric}
                                dispatch={areaOriginDestinationReportDispatch}
                            />
                        )}
                    {/* Displays the metadata for the selected area o/d report (component not built, based on <RideshedMetadataPanel> */}
                    {map && (
                        <AreaOriginDestinationReportPopup
                            map={map}
                            hoveredArea={
                                areaOriginDestinationReportState.hoveredArea
                            }
                            hoveredAreaData={
                                areaOriginDestinationReportState.hoveredAreaData
                            }
                            selectedAreaId={
                                areaOriginDestinationReportState.selectedArea
                                    ?.id || null
                            }
                            metric={areaOriginDestinationReportState.metric}
                        />
                    )}
                </ModePicker>
                {/* This is the right-hand sidebar with the download button, map settings (satellite etc) and custom datasets. */}
                <AreaOriginDestinationReportSidebar
                    mapOptions={mapOptions}
                    dispatchMapOptions={dispatchMapOptions}
                    metric={areaOriginDestinationReportState.metric}
                    selectedAreaOriginDestinationReportId={
                        areaOriginDestinationReportState
                            .selectedAreaOriginDestinationReportInfo?.id || null
                    }
                    selectedArea={areaOriginDestinationReportState.selectedArea}
                />
            </div>
        </>
    );
};

export default MapAreaOriginDestinationReports;

import { Dispatch, useEffect, useMemo } from 'react';
import { EventData, Layer, Map, MapLayerMouseEvent, Sources } from 'mapbox-gl';
import getFeatureForPoint from '../../common/get-feature-for-point';
import { AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY } from '../../layers/routes/layers';
import {
    AreaOriginDestinationReportActions,
    AreaOriginDestinationReportState
} from './reducer';
import { DispatchMapOptions } from 'page-explore';
import { MultiPolygon } from 'geojson';
import { getDataColor } from 'utils/helpers';
import {
    useAreaOriginDestinationReport,
    useAreaOriginDestinationReportsInfo
} from 'page-explore/layers/area-origin-destination-counts/api';

interface AreaOriginDestinationReportEffects {
    layers: Layer[];
    sources: Sources;
    loading: boolean;
}

export type AreaData = Array<{
    id: string;
    name: string;
    slug: string;
    geometry: MultiPolygon;
    geometryJson: string;
}>;

export type AreaCount = {
    count: number;
    percentage: number;
};

export type AreaFeature = GeoJSON.Feature<
    GeoJSON.MultiPolygon,
    {
        color: string;
        id: string;
        name: string;
    }
>;

// build the areas source to display aois on the map.
export const getAreasSource = (areas: AreaData) => {
    const source = {
        type: 'geojson' as const,
        cluster: false,
        data: {
            type: 'FeatureCollection' as const,
            features: [
                ...areas.map((aoi, i) => ({
                    type: 'Feature',
                    id: i,
                    geometry: aoi.geometry,
                    properties: {
                        color: getDataColor(i + 2).backgroundColor,
                        id: aoi.id,
                        name: aoi.name
                    }
                }))
            ] as AreaFeature[]
        }
    };
    return source;
};

/**
 * Builds and returns the mapbox layers and sources for the current set of
 * areas in the area o/d report.
 *
 * `sources` contains our area o/d report data to display on the map, including relevant
 * properties such as the name and trip count for each aoi.
 * `layers` tells mapbox how to display and style the data provided by the sources.
 */
export const useAreaOriginDestinationReportEffects = (
    map: Map | null,
    dispatchMapOptions: DispatchMapOptions,
    state: AreaOriginDestinationReportState,
    dispatch: Dispatch<AreaOriginDestinationReportActions>
): AreaOriginDestinationReportEffects => {
    const [
        areaOriginDestinationReportsInfo,
        isAreaOriginDestinationReportsInfoLoading
    ] = useAreaOriginDestinationReportsInfo();

    useEffect(() => {
        dispatch({
            type: 'SET_AREA_ORIGIN_DESTINATION_REPORTS_INFO',
            payload: {
                areaOriginDestinationReportsInfo: areaOriginDestinationReportsInfo
            }
        });
    }, [dispatch, areaOriginDestinationReportsInfo]);

    const [
        areaOriginDestinationReport,
        isAreaOriginDestinationReportLoading
    ] = useAreaOriginDestinationReport({
        areaOriginDestinationReportId: state.selectedAreaOriginDestinationReportInfo
            ? state.selectedAreaOriginDestinationReportInfo.id
            : null,
        metric: state.metric,
        selectedAreaId: state.selectedArea ? state.selectedArea.id : null
    });

    useEffect(() => {
        dispatch({
            type: 'SET_SELECTED_AREA_ORIGIN_DESTINATION_REPORT_DATA',
            payload: {
                areaOriginDestinationReport
            }
        });
    }, [dispatch, areaOriginDestinationReport]);

    const areas = useMemo(() => {
        return (
            state.selectedAreaOriginDestinationReportInfo?.areas.map(area => {
                return {
                    ...area,
                    geometry: JSON.parse(area.geometryJson) as MultiPolygon
                };
            }) || []
        );
    }, [state.selectedAreaOriginDestinationReportInfo]);

    const sources: Sources = useMemo(() => {
        return {
            [AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY]: getAreasSource(
                areas
            )
        };
    }, [areas]);

    const layers = useMemo(() => {
        let layers: Layer[] = [];
        layers.push({
            id: `origin-destination-report-areas-fill`,
            type: 'fill',
            source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
            layout: {
                visibility: 'visible'
            },
            paint: {
                'fill-color': ['get', 'color'],
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'selected'], false],
                    0.75,
                    ['boolean', ['feature-state', 'hover'], false],
                    0.5,
                    0.25
                ],
                'fill-outline-color': ['get', 'color']
            }
        });
        layers.push({
            id: `origin-destination-report-areas-line`,
            type: 'line',
            source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
            layout: {
                visibility: 'visible'
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-width': 3
            }
        });

        return layers;
    }, []);

    const loading =
        isAreaOriginDestinationReportsInfoLoading ||
        isAreaOriginDestinationReportLoading;

    useEffect(() => {
        if (map) {
            const listener = (event: MapLayerMouseEvent & EventData) => {
                const allFeatures = map.querySourceFeatures(
                    AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY
                );
                const feature = getFeatureForPoint(
                    map,
                    ['origin-destination-report-areas-fill'],
                    event.point
                );

                if (feature && (!feature.properties || !feature.geometry)) {
                    // We have a feature, but it's not one of the ones we expect.
                    // This might be a custom dataset point or other overlaid data,
                    // which will have it's own click handler.
                    return;
                }
                if (feature && feature.properties && feature.geometry) {
                    map.setFeatureState(
                        {
                            source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                            id: feature.id
                        },
                        { selected: true }
                    );

                    allFeatures.forEach(f => {
                        if (f.id !== feature.id) {
                            map.setFeatureState(
                                {
                                    source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                                    id: f.id
                                },
                                { selected: false }
                            );
                        }
                    });

                    dispatch({
                        type: 'SET_SELECTED_AREA',
                        payload: {
                            selectedArea: {
                                id: feature.properties.id,
                                name: feature.properties.name,
                                color: feature.properties.color,
                                geometry: feature.geometry as AreaFeature['geometry']
                            }
                        }
                    });
                } else if (!feature) {
                    allFeatures.forEach(f => {
                        map.setFeatureState(
                            {
                                source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                                id: f.id
                            },
                            { selected: false }
                        );
                    });
                    dispatch({
                        type: 'SET_SELECTED_AREA',
                        payload: {
                            selectedArea: null
                        }
                    });
                }
            };
            map.on('click', listener);
            return () => {
                map.off('click', listener);
            };
        }
    }, [map, dispatch]);

    useEffect(() => {
        if (map) {
            const listener = ({ point }) => {
                let feature = getFeatureForPoint(
                    map,
                    ['origin-destination-report-areas-fill'],
                    point
                );
                const allFeatures = map.querySourceFeatures(
                    AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY
                );
                if (feature) {
                    map.getCanvas().style.cursor = 'pointer';
                } else {
                    map.getCanvas().style.cursor = '';
                }
                if (feature && (!feature.properties || !feature.geometry)) {
                    return;
                }
                if (feature && feature.properties && feature.geometry) {
                    const selectedFeature = feature;
                    dispatch({
                        type: 'SET_HOVERED_AREA',
                        payload: {
                            hoveredArea: {
                                id: feature.properties.id,
                                name: feature.properties.name,
                                color: feature.properties.color,
                                geometry: feature.geometry as AreaFeature['geometry']
                            }
                        }
                    });

                    map.setFeatureState(
                        {
                            source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                            id: feature.id
                        },
                        { hover: true }
                    );

                    allFeatures.forEach(f => {
                        if (f.id !== selectedFeature.id) {
                            map.setFeatureState(
                                {
                                    source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                                    id: f.id
                                },
                                { hover: false }
                            );
                        }
                    });
                } else if (!feature) {
                    dispatch({
                        type: 'SET_HOVERED_AREA',
                        payload: {
                            hoveredArea: null
                        }
                    });
                    allFeatures.forEach(f => {
                        map.setFeatureState(
                            {
                                source: AREA_ORIGIN_DESTINATION_REPORT_AREAS_SOURCE_KEY,
                                id: f.id
                            },
                            { hover: false }
                        );
                    });
                }
            };

            map.on('mousemove', listener);
            return () => {
                map.off('mousemove', listener);
            };
        }
    }, [map, dispatch]);

    useEffect(() => {
        let loadingTimeout: NodeJS.Timeout | undefined;
        if (loading === true) {
            loadingTimeout = setTimeout(() => {
                dispatchMapOptions({
                    type: 'set loading data',
                    value: true
                });
            }, 100);
        } else {
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        }

        return () => {
            if (loadingTimeout) clearTimeout(loadingTimeout);
            dispatchMapOptions({
                type: 'set loading data',
                value: false
            });
        };
    }, [dispatchMapOptions, loading]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set sources',
            payload: {
                dataType: 'ridesheds',
                sources
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set sources',
                payload: { dataType: 'ridesheds', sources: {} }
            });
    }, [dispatchMapOptions, sources]);

    useEffect(() => {
        dispatchMapOptions({
            type: 'set layers',
            payload: {
                dataType: 'ridesheds',
                layers
            }
        });
        return () =>
            dispatchMapOptions({
                type: 'set layers',
                payload: { dataType: 'ridesheds', layers: [] }
            });
    }, [dispatchMapOptions, layers]);

    return {
        layers,
        sources,
        loading
    };
};

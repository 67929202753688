import React from 'react';

import { MapOptions, DispatchMapOptions } from 'page-explore';
import { FormattedMessage } from 'react-intl';
import { useMapView } from 'common/use-map-view';
import DownloadButton from 'common/download-button';
import Sidebar from '../../common/sidebar/sidebar';
import SidebarCustomDatasets from '../../common/sidebar/custom-datasets';
import { Metric, SelectedArea } from './reducer';

interface ExportAreaOriginDestinationReportProps {
    metric: 'starts' | 'ends';
    selectedAreaOriginDestinationReportId: string | null;
    selectedArea: SelectedArea | null;
}

const ExportAreaOriginDestinationReport = ({
    metric,
    selectedAreaOriginDestinationReportId,
    selectedArea
}: ExportAreaOriginDestinationReportProps) => {
    const slug = useMapView().slug;
    const selectedAreaId = selectedArea?.id;
    if (!selectedAreaOriginDestinationReportId) return null;
    const params = {
        mapview_slug: slug,
        metric: metric,
        selectedAreaOriginDestinationReportId: selectedAreaOriginDestinationReportId
    };

    return (
        <>
            {selectedAreaOriginDestinationReportId && (
                <DownloadButton
                    icon="Download"
                    path="area-origin-destination-report"
                    filename={`${slug}_area_origin_destination_report_${metric}_${selectedAreaOriginDestinationReportId}_export(CONFIDENTIAL).zip`}
                    params={
                        selectedAreaId
                            ? {
                                  ...params,
                                  selectedAreaId: selectedAreaId
                              }
                            : params
                    }
                    failureReason="download-failure-time-range"
                >
                    <FormattedMessage
                        key="button-label"
                        defaultMessage="Export Data"
                    />
                </DownloadButton>
            )}
        </>
    );
};

interface AreaOriginDestinationReportSidebarProps {
    mapOptions: MapOptions;
    dispatchMapOptions: DispatchMapOptions;
    metric: Metric;
    selectedAreaOriginDestinationReportId: string | null;
    selectedArea: SelectedArea | null;
}

const AreaOriginDestinationReportSidebar = ({
    mapOptions,
    dispatchMapOptions,
    metric,
    selectedAreaOriginDestinationReportId,
    selectedArea
}: AreaOriginDestinationReportSidebarProps) => {
    return (
        <Sidebar
            mapOptions={mapOptions}
            dispatchMapOptions={dispatchMapOptions}
            headerButton={
                <ExportAreaOriginDestinationReport
                    metric={metric}
                    selectedAreaOriginDestinationReportId={
                        selectedAreaOriginDestinationReportId
                    }
                    selectedArea={selectedArea}
                />
            }
        >
            <SidebarCustomDatasets />
        </Sidebar>
    );
};

export default AreaOriginDestinationReportSidebar;
